import './App.css';
import photo from './static/sherri-chris-photo.jpeg';

function App() {
  const imgStyle = {
    width: '85vw',
  };

  return (
    <div className="App">
      <h1>Congratulations Honey!!!</h1>

      <p>
        So proud of you and happy for your many adventures to come! Can't believe you got a graduate degree from an Ivy League... Crazy to think about, but it fits you! You care so much and are so passionate and deserving of success and I can't wait to see what you do next!
        <br></br><br></br>
        Good luck as you grow your career and see how far you can go from here. And I'll be there and with you every step of the way to support, counsel, and reassure. And now you have *another* developer friend who can help you make a personal website 🤪
        <br></br><br></br>
        So yeah, I have the domain, that's the surprise, and I can help you make it how you like it, and put links to your socials and your projects 🙂
      </p>

      <img src={photo} alt="Selfie of me and Sherri on the East River" style={imgStyle} />

      <p>Love, Chris 🫰</p>
    </div>
  );
}

export default App;
